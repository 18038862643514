export default class FeatureFlags {
  dashboardDrillDown: boolean
  jobTripMap: boolean
  jobComments: boolean
  jobsListFilterBeyond30Days: boolean
  jobPlannedActualTimesEditing: boolean
  jobAssignmentGroups: boolean
  customFieldVersioningEnabled: boolean
  assignmentFormRequirements: boolean
  weekViewDoubleHeightJobCard: boolean
  submitJobForApproval: boolean
  lineItems: boolean
  invoices: boolean
  connectionRequests: boolean
  displayLocationVisitedEvents: boolean
  privateLocations: boolean
  batchSyncing: boolean
  createGuestRequesters: boolean

  constructor(json) {
    this.dashboardDrillDown = json.dashboard_drill_down
    this.jobTripMap = json.job_trip_map
    this.jobComments = json.job_comments
    this.jobsListFilterBeyond30Days = json.jobs_list_filter_beyond30_days
    this.jobPlannedActualTimesEditing = json.job_planned_actual_times_editing
    this.jobAssignmentGroups = json.job_assignment_groups
    this.customFieldVersioningEnabled = json.custom_field_versioning_enabled
    this.assignmentFormRequirements = json.assignment_form_requirements
    this.weekViewDoubleHeightJobCard = json.week_view_double_height_job_card
    this.submitJobForApproval = json.submit_job_for_approval
    this.lineItems = json.line_items
    this.invoices = json.invoices
    this.connectionRequests = json.connection_requests
    this.displayLocationVisitedEvents = json.display_location_visited_events
    this.privateLocations = json.private_locations
    this.batchSyncing = json.batch_syncing
    this.createGuestRequesters = json.create_guest_requesters
  }
}
