import { computed, Ref } from 'vue'
import { useInvoiceQuery, useInvoiceSyncTargetsQuery } from '@/api/invoice'
import { isQbdSyncDetails, QbdSyncDetails } from '@/models/invoice'
import moment from 'moment/moment'
import { useQuery } from '@tanstack/vue-query'
import { getAdminPortalConfig } from '@/api/config'
import { useStore } from 'vuex-composition-helpers'

export const useBatchSyncStates = (
  batchId: Ref<string | null | undefined>,
  syncTargetId: Ref<string | null | undefined>
) => {
  const { data: batch } = useInvoiceQuery(batchId)
  const { data: batchSyncTargets } = useInvoiceSyncTargetsQuery(batchId)

  const { data: config } = useQuery({
    queryKey: ['config'],
    queryFn: getAdminPortalConfig,
    cacheTime: Infinity,
    staleTime: moment.duration(1, 'days').asMilliseconds(),
  })
  const store = useStore()
  const orgId = computed<string | undefined>(
    () => store.getters['permissions/getOrganizationId']
  )

  const batchHasSyncTarget = computed(
    () =>
      batchSyncTargets.value?.some((st) => st.id === syncTargetId.value) ??
      false
  )

  const syncTarget = computed(() =>
    batchSyncTargets.value?.find((st) => st.id === syncTargetId.value)
  )

  const lastSyncRunLogLink = computed(() => {
    if (
      syncTarget.value &&
      isQbdSyncDetails(syncTarget.value.details) &&
      orgId.value &&
      config.value
    ) {
      return `${config.value.data.url}/organization/${orgId.value}/history/${syncTarget.value.details.lastRunId}`
    }
    return undefined
  })

  // If an invoice has never been synced before, but has queued for sync
  const isInitialSyncPending = computed(
    () => !!(syncTarget.value && !isQbdSyncDetails(syncTarget.value.details))
  )

  // If an invoice has been synced before, and it has been modified since the last sync time
  const isSubsequentSyncPending = computed(
    () =>
      !!(
        syncTarget.value &&
        isQbdSyncDetails(syncTarget.value.details) &&
        syncTarget.value.details.syncStatus === 'Success' &&
        syncTarget.value.details.lastSyncedAt &&
        ((batch.value?.lastModified &&
          moment(syncTarget.value.details.lastSyncedAt).isBefore(
            batch.value.lastModified
          )) ||
          (syncTarget.value.lastRequestedSyncAt &&
            moment(syncTarget.value.details.lastSyncedAt).isBefore(
              syncTarget.value.lastRequestedSyncAt
            )))
      )
  )

  // If an invoice sync success, and there's no modification to the invoice since the last sync time
  const isSyncSuccess = computed(
    () =>
      !!(
        syncTarget.value &&
        isQbdSyncDetails(syncTarget.value.details) &&
        syncTarget.value.details.syncStatus === 'Success' &&
        syncTarget.value.details.lastSyncedAt &&
        batch.value?.lastModified &&
        moment(syncTarget.value.details.lastSyncedAt).isSameOrAfter(
          batch.value.lastModified
        ) &&
        syncTarget.value.lastRequestedSyncAt &&
        moment(syncTarget.value.details.lastSyncedAt).isSameOrAfter(
          syncTarget.value.lastRequestedSyncAt
        )
      )
  )

  const isSyncFailure = computed(
    () =>
      !!(
        syncTarget.value &&
        isQbdSyncDetails(syncTarget.value.details) &&
        syncTarget.value.details.error &&
        syncTarget.value.details.syncStatus === 'Failed'
      )
  )

  const syncFailureMessage = computed(() => {
    if (isSyncFailure.value) {
      return (syncTarget.value!.details as QbdSyncDetails).error
    }
  })

  const lastSyncedAt = computed(() => {
    if (syncTarget.value && isQbdSyncDetails(syncTarget.value.details)) {
      return syncTarget.value.details.lastSyncedAt
    }
  })

  return {
    syncTarget,
    lastSyncRunLogLink,
    batchHasSyncTarget,
    isInitialSyncPending,
    isSubsequentSyncPending,
    isSyncSuccess,
    isSyncFailure,
    syncFailureMessage,
    lastSyncedAt,
  }
}
